<template>
  <div id="app">
    
    <Navbar v-if="this.userLogin" />
    <div class="main">
      <div class="sideBar" v-if="this.userLogin">
        <Sidebar />
      </div>
      <div class="routePage">
        <router-view />
      </div>
    </div>
    <div class="footer" v-if="this.userLogin">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import moment from 'moment';
import './assets/Miraculous&Christmas.ttf'

export default {
  name: "App",
  data() {
    return {
      audioSrc: require('@/assets/okgas.mp3')
    }
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
  },
  computed: {
    userLogin() {
      return this.$store.state.isLogin;
    },
  },
  created() {
    this.cekSotrage()
    // this.tryPlayMusic();
    if (localStorage.getItem("access_token")) {
      this.$store.commit("setIsLogin", true);
    } else {
      this.$store.commit("setIsLogin", false);
      this.$router.push("/login");
    }
  },
  methods: {

    cekSotrage() {
      // Swal.fire("Peringatan", "Segera Lengkapi data 2023 Anda", "warning");
      let y = 0
      let thisDate = moment(new Date()).format("YYYY-MM-DD")
      if (!localStorage.getItem("access_token")) y++
      if (localStorage.getItem("date") !== thisDate || !localStorage.getItem("date")) y++
      
      if(y > 0) {
        this.$store.commit("setIsLogin", false);
        localStorage.clear()
        this.$router.push("/login")
      }
    },
    // tryPlayMusic() {
    //   console.log("Trying to play music");
    //   const audioElement = this.$refs.audio;

    //   // Coba langsung memutar audio saat komponen di-mount
    //   audioElement.play().then(() => {
    //     console.log("Music started playing automatically");
    //     this.playError = false;
    //   }).catch((error) => {
    //     console.error("Error trying to play music automatically:", error);
    //     this.playError = true;
    //   });
    // },
    // playMusic() {
    //   console.log("Play button clicked");
    //   const audioElement = this.$refs.audio;

    //   // Pemutaran manual jika gagal otomatis
    //   audioElement.play().then(() => {
    //     console.log("Music started playing");
    //     this.playError = false;
    //   }).catch((error) => {
    //     console.error("Error trying to play music:", error);
    //     this.playError = true;
    //   });
    // }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif,;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  padding: 0;
}
* {
  font-family: "Segoe UI";
}
.main {
  /* background-color: aqua; */
  width: 100%;
  /* height: 100%; */
  display: flex;
}

.routePage {
  width: 100%;
  display: flex;
  min-height: 85vh;
}

.mainPage {
  min-height: 80vh;
  width: 100%;
  height: 100%;
  padding: 16px 8px 16px 16px;
  background-color: #faf7f7;
}

.sideBar {
  max-width: 230px;
  min-width: 150px;
  /* background-color: #2c3e50; */
}

.modal p {
  text-align: left;
}

.modal {
  padding: 10px !important;
  /* background-color: #17a2b8; */
}

/* in Main Page */
.inRow {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  justify-content: center;
}
.inCol {
  display: flex;
  flex-direction: column;
}
#headPage {
  text-align: left;
  font-size: 2rem;
  font-weight: 800;
  margin: 0;
  color: #4568b9;
  font-family: Avenir, Helvetica, Arial, sans-serif,;
  /* font-family: 'Mountains of Christmas', serif; */
}
/* memberikan class untuk row */
.inLine {
  display: flex;
}

table td {
  text-align: left;
}

.uang {
  text-align: right;
}

#cardTable {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}

.tableRow {
  overflow-x: auto;
  overflow-y: auto;
}

#tableData {
  min-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 10px;
}
#tableData th {
  font-size: 12px;
}
#tableData td {
  font-size: 11px;
  padding: 2px 3px;
  font-weight: 400;
}
#tableData td #action {
  background-color: aquamarine;
}
#tableData button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  padding: 0px;
}

#tableData lord-icon {
  width: 20px;
  height: 20px;
}
#titleRow {
  min-height: 24px;
  text-align: left;
  font-size: 32px;
  margin: 10px 0 0 0;
}
#inputTitle {
  margin: 0;
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  color: rgb(134, 134, 134);;
}
.filterRow #cardFilter {
  flex: 1;
  margin: 0 10px;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3px 10px;
  margin: 1rem 0.2rem;
  padding: 20px;
}
#titleCard {
  text-align: left;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

#amountCard {
  text-wrap: nowrap;
  text-align: right;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
}

/* Card */
#startBalance {
  background-color: #17a2b8;
}
#inCard {
  background-color: #28a745;
}
#outCard {
  background-color: #e83e8c;
}
#balanceCard {
  background-color: #007bff;
}
#waitingCard {
  background-color: #e52903;
}

.loading {
  min-width: 100%;
}

.spinner-border {
  background-color: #007bff;
  width: 200px;
  height: 200px;
}

#printer {
  background-color: transparent;
  border: none;
  text-align: right;
  max-width: 24px;
}

/* Pagination */
.pagination button {
  background-color: transparent;
  border: none;
}

.pagination p {
  margin: 0;
}

.pagination {
  width: 100%;
  justify-content: center;
}

#addModal {
  background-color: #188f1e;
  border: none;
}
#resetFilter {
  margin-left: 10px;
  background-color: #e83e8c;
  border: none;
}
/* modal */

.modal .inCol {
  flex: 1;
  margin: 0 2px;
}

.modal p {
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.modal .inRow {
  margin-top: 3px;
}

.custom-select,
.form-control {
  font-size: 11px !important;
  padding: 4px 20px 4px 8px !important;
}
b-input {

  font-size: 40px;
  background-color: #17a2b8;
}

/* option {
  font-size: 11px;
} */


/* Print Area */
#printArea {
  display: none;
  font-size: 12px;
  background-color: #ffffff;
  padding: 50px 24px 12px 24px;
}

.headPrint {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
}
#titlePrint {
  font-size: 16px;
}
#datePrint {
  font-size: 11px;
}

hr {
  margin-top: 0rem;
}

#printTable td {
  font-size: 12px;
  padding: 2px 10px 2px 8px;
}
#printTable #keyTable {
  /* padding-right: 200px !important; */
  /* background-color: #007BFF; */
  width: 150px;
}
#printTable #titikDua {
  /* background-color: #17A2B8; */
  width: 15px;
}
.signArea .inRow {
  justify-content: space-around;
}
.signArea #name {
  text-align: center;
  margin-bottom: 60px;
}


img#status{
    min-width: 28px;
    max-width: 40px;
}
#edited {
  min-width: 28px;
    max-width: 40px;
}


td img {
  max-width: 18px;
}

#deleteButton {
  background-color: transparent;
  border: none;
}

.modal button img {
  max-width: 24px;
}

#statusModal {
  justify-items: center;
  align-content: center;
}

.modal-content {
  max-width: 50rem;
  justify-self: center;
  /* background-color: #007bff; */
  padding: 0px;
  /* margin-top: 4rem; */
}

#mainModal{
  align-content: center;
  justify-self: center;
  /* background-color: #007bff; */

}

#mainModal___BV_modal_content_{
  min-width: 60rem;
justify-self: center;
}
</style>
