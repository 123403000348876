<template>
  <div class="mainPage">
    <p id="headPage">Pembayaran</p>
    <div class="cardRow">
      <div class="inRow">
        <div class="card" id="startBalance">
          <p id="titleCard">Total Pengajuan</p>
          <p id="amountCard">{{formatUang(totalAmount)}}</p>
        </div>
        <div class="card" id="waitingCard">
          <p id="titleCard">Belum Diproses</p>
          <p id="amountCard">{{formatUang(totalWaiting)}}</p>
        </div>
        <div class="card" id="inCard">
          <p id="titleCard">Disetujui</p>
          <p id="amountCard">{{formatUang(totalApprove)}}</p>
        </div>
        <div class="card" id="balanceCard">
          <p id="titleCard">Telah dibayarkan</p>
          <p id="amountCard">{{formatUang(totalPaid)}}</p>
        </div>
      </div>
    </div>

    <div class="filterRow">
       
      <div class="inRow">
        <div class="inCol" id="cardFilter"  v-if="role == 'owner'">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="filter.findBranchId" @change="this.getDataTable">
            <option value="0" selected >Seluruh Perusahaan </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Mulai</p>
          <b-input type="date" v-model="filter.startDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="filter.endDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Status</p>
          <b-select v-model="filter.status" @change="this.getDataTable" >
            <option value="">Semua Status</option>
            <option value="1">Waiting</option>
            <option value="2">Disetujui</option>
            <option value="3">Ditolak</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Pembayaran</p>
          <b-select v-model="filter.isPay" @change="this.getDataTable" >
            <option value="">Semua Status</option>
            <option :value=1>Sudah Bayar</option>
            <option :value=0>Belum Bayar</option>
          </b-select> 
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="filter.keyword" @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'owner' || role == 'payment' || role == 'cashier' ">Tambah Data</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
        <img src="../assets/printer.png" id="printer" alt="">
      </button>
      <button class="appButton button" id="approve" v-if="this.checks.length > 0"  @click="changeStatus(2)"> <img src="../assets/check.png" alt=""> Terima</button>
      <button class="appButton button" id="reject" v-if="this.checks.length > 0"  @click="changeStatus(3)"> <img src="../assets/wrong.png" alt="">Tolak</button>
      <button class="appButton button" id="waiting" v-if="this.checks.length > 0"  @click="changeStatus(1)"><img src="../assets/warning.png" alt=""> Waiting</button>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Check</th>
            <th>Nomor Ref</th>
            <th>Tanggal</th>
            <th v-if="role == 'owner'">Cabang</th>
            <th>Project</th>
            <th>Akun</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <th>Approve</th>
            <th>Pembayaran</th>
            <th>Verifikasi</th>
            <th v-if="role == 'payment' || 'owner'" >Aksi</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="e in dataTable" :key="e.id">
              <!-- <td>{{ e.IsVerifed }}</td> -->
              <td class="text-center"><input type="checkbox" v-model="checks" :value="e.id" /></td>
              <td>{{ e.refNo }}</td>
              <td>{{ formatDate(e.date) }}</td>
              <td v-if="role == 'owner'">{{ e.Branch?.branchName || e.BranchId }}</td>
              <td> {{ e.Project?.projectName  || "-" }}</td>
              <td> {{ e.SubMataAnggaran?.subMataAnggaranName  || "-" }}</td>
              <td> {{e.notes}} </td>
              <td class="text-right">{{ formatUang(e.amount) }}</td>
              <td class="text-center" >
                <button @click="showChangeStatus(e)"  class="text-center">
                  <img src="../assets/warning.png" v-if="e.status == 1">
                  <img src="../assets/check.png" v-if="e.status == 2">
                  <img src="../assets/wrong.png" v-if="e.status == 3"> 
                </button>
                  {{ formatDate(e.approveDate) }}
              </td>              
              <td id="action" class="text-center" v-if="e.isPay" >
                  <img src="../assets/check.png" > {{ formatDate(e.payDate) }}
              </td>
              <td class="text-center" v-if="!e.isPay">
                <button @click.prevent="checkPay(e)">
                  <img src="../assets/wrong.png" > 
                </button>
              </td>
              <td class="text-center" v-if="!e.IsVerifed">
                <button @click.prevent="changeVerifed(e)">
                  <img src="../assets/wrong.png" > 
                </button>
              </td>
              <td class="text-center" v-if="e.IsVerifed">
                <button @click.prevent="changeVerifed(e)"> 
                  <img src="../assets/check.png" > 
                </button>
              </td>

              <td  id="action" class="text-center" style="display: flex; flex-wrap: nowrap;">
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button>
                <button @click.prevent="confirmDelete(e)" title="Print" >
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
            </tr>
          
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>

    </div>

    <!-- Modal Payment -->
    <div class="modal" id="22" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pengajuan Pembayaran</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">

        </div>
      </div>
      <!-- </div> -->
    </div>
    <b-modal ref="mainModal" id="mainModal" title="Pengajuan Pembayaran" @ok="isEdit ? editData() : addData() ">
      <!-- {{ this.add.SubMataAnggaranId }} -->
        <div class="inRow">
          <div class="inCol"  v-if="this.role == 'owner'">
            <p id="inputTitle">Cabang</p>
            <b-select v-model="add.BranchId" @change="this.getProject">
              <option v-for="e in this.branches" :key="e.id" :value="e.id">{{ e.branchName }}</option>
            </b-select>
          </div>
          <div class="inCol" >
            <p id="inputTitle">Proyek</p>
            <b-select v-model="add.ProjectId" required>
              <option v-for="e in this.projects " :key="e.id" :value="e.id">{{ e.projectName }}</option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="inputTitle">Akun</p>
            <div @click="toggleDropdown" class="dropdown-header">
              {{ selected.subMataAnggaranName || "klik untuk mencari" }}
            </div>
            <div v-if="open" class="dropdown">
              <!-- Input berada di dalam dropdown untuk pencarian -->
              <input
                type="text"
                v-model="search"
                placeholder="Type to search..."
                class="dropdown-search"
                id="input-dropdown"
              />
              <div
                v-for="option in filteredOptions"
                :key="option.id"
                @click="select(option)"
                class="dropdown-item"
              >
                {{ option.subMataAnggaranName }}
              </div>
            </div>
            <!-- <b-form-input list="my-list-id" @input="getSubMa($event)"></b-form-input>
            <datalist id="my-list-id">
              <option v-for="e in this.submas" :key="e.id" :value="e.subMataAnggaranName">{{ e.id }}  {{ e.name }} {{ e.coa }}</option>
            </datalist> -->
          </div>
          <div class="inCol">
            <p id="inputTitle">Keterangan</p>
            <b-input type="text" v-model="add.notes" required></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Jumlah</p>
            <b-input class="text-right" type="text" v-model="add.amount" @input="currencyNumber($event)"  required></b-input>
          </div>
        </div>
      <!-- <b-button class="btn btn-secondary" @click="$refs.mainModal.hide()">Close</b-button> -->
    </b-modal>
    <!-- Modal Status -->

    <div class="modal" id="statusModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Status</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="changeStatus()">

            <div class="inRow">
              <div class="inCol"  v-if="this.role == 'owner'">
                <p id="inputTitle">Status</p>
                <b-select v-model="status">
                  <option value="0" disabled> - Silahkan Pilih - </option>
                  <option value="1"> Waiting </option>
                  <option value="2"> Disetujui </option>
                  <option value="3"> Ditolak </option>
                </b-select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Ubah</button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Modal Pembayaran -->
    <div class="modal" id="payment" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pembayaran</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="changePay()">

            <div class="inRow">
              <div class="inCol">
                <p id="inputTitle">Tanggal Pembayaran</p>
                <b-input type="date" v-model="date"></b-input>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Ubah</button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Print -->
    <div id="printArea">

      <div class="headPrint">
        <p id="titlePrint" >Persetujuan Pembayaran</p>
        <p id="brancName"> {{ print.branchName }} </p>
        <p id="datePrint"> {{ print.date }} </p>
      </div>
      <hr>
      <div class="mainPrint">
        <table class="table table-borderless" id="printTable">
            <tr>
              <td id="keyTable">Nomor</td>
              <td id="titikDua">:</td>
              <td>{{ print.nomor }}</td>
            </tr>
            <tr>
              <td id="keyTable">TanggaL Input</td>
              <td id="titikDua">:</td>
              <td>  {{ print.approveDate }}</td>
            </tr>
            <tr>
              <td id="keyTable">TanggaL Persetujuan</td>
              <td id="titikDua">:</td>
              <td> <img src="../assets/check.png" > {{ print.approveDate }}</td>
            </tr>
            <tr>
              <td id="keyTable">Tanggal Pembayaran</td>
              <td id="titikDua">:</td>
              <td><img src="../assets/check.png" > {{ print.payDate }}</td>
            </tr>
            <tr>
              <td id="keyTable">Jumlah</td>
              <td id="titikDua">:</td>
              <td>Rp.{{ print.amount }}</td>
            </tr>
            <tr>
              <td id="keyTable">Keterangan</td>
              <td id="titikDua">:</td>
              <td>{{ print.notes }}</td>
            </tr>
        </table>
        <hr>
      </div>
      <div class="signArea">
        <div class="inRow">
          <div class="inCol">
            <p id="name">Pembayaran</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Akuntansi</p>
            <p>(............................)</p>
          </div>
          <div class="inCol">
            <p id="name">Man. keuangan</p>
            <p>(............................)</p>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      isEdit: false,
      isLoading: false,
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      keyword : '',
      dataTable: [],
      submas: [],
      projects: [],
      checks: [],
      status: 0,
      totalAmount : 0,
      totalApprove : 0,
      totalReject : 0,
      totalPaid : 0,
      totalWaiting: 0,
      showModalAdd: false,
      date: '',

      // modal search
      selected: {},
      search: '',
      open: false,
      filteredOptions: [],


      add : {
        BranchId: 0,
        ProjectId: 0,
        SubMataAnggaranId: 0,
        notes: '',
        date: '',
        amount: 0
      },
      filter: {
        keyword: '',
        isApprove: "",
        isPay: '',
        status: '',
        startDate: '',
        endDate: '',
        findBranchId: "0"
      },

      print: {
        refNo: '',
        branchName: '',
        approveDate: '',
        payDate: '',
        notes: '',
        amount: '',
        date: '',
        inputDate: ''
      }

    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getProject()
    
  },
  watch:{
     async search(newSearch) {
      try {
        if(this.search.length < 2) return []
        let {data} = await axios({
          method: "GET",
          url: "/payment/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            type: this.type,
            name: this.search.toLowerCase(),
          }
        })

        
        return this.filteredOptions = data
        
      } catch (err) {
        return  this.filteredOptions = []
      }
    }
  },

  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem('role')
    }
  },  
  methods: {
    
    showAdd() {
      this.isEdit = false
      this.$refs.mainModal.show();
    },

    toggleDropdown() {
      this.open = !this.open;
      if (!this.open) this.search = ""; // Reset search when closing dropdown
    },

    select(option) {
      this.selected = option;
      this.add.SubMataAnggaranId = option.id;      
      this.open = false;
      this.search = option.text; // Set search to selected option text
    },
    closeDropdown() {
      this.open = false;
    },
    
    resetFilter() {
      this.filter = {
        keyword: '',
        isApprove: "",
        isPay: '',
        status: '',
        startDate: '',
        endDate: '',
      },
      this.getDataTable()
      
    },
    async getProject() {
      try {
        let {data} = await axios({
          method: "GET",
          url: "/user/project",
          params: {
            BranchId: this.add.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = data
      } catch(err) {
        console.log(err);
      }
    },
    async getSubMa(e) {
      try {
        let {data} = await axios({
          method: "GET",
          url: "/payment/subma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            name: e
          }
        })
        this.submas = data
        console.log(e,"-----");
        let avaliableOptions =this.submas.map(x => x.subMataAnggaranName )
        
        if(!avaliableOptions.includes(e)) {
          this.add.SubMataAnggaranId = 0
          return
        }
        let id = this.submas.find(x => x.subMataAnggaranName).id
        this.add.SubMataAnggaranId = id

      } catch(err) {
        console.log(err);
      }
    },
    setPrintData(e) {
      if(!e.isApprove || !e.isPay) {
        return Swal.fire('Maaf', 'Data ini belum disetujui / dibayar', 'error');
      }

      this.print.branchName = e.Branch.branchName,
      this.print.date = moment(new Date()).format('DD-MM-YYYY')
      this.print.inputDate = moment(e.date).format('DD-MM-YYYY')
      this.print.nomor = e.refNo
      this.print.amount = this.formatUang(e.amount)
      this.print.notes = e.notes
      this.print.approveDate = this.formatDate(e.approveDate)
      this.print.payDate = this.formatDate(e.payDate)
      this.exportToPDF()
    },

    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Voucer.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },

    showChangeStatus(e) {
      if(this.role !== 'owner') Swal.fire('Maaf', 'Anda tidak memiliki akses', 'error');


      else if(e.isPay) {
        return Swal.fire('Maaf', 'Maaf Data ini tidak bisa diubah', 'error');
      } else $('#statusModal').modal('show')
      this.id= e.id
      this.checks.push(e.id)
    },

    checkPay(e) {
      this.id = e.id
      if(this.role !== 'cashier') return Swal.fire('Maaf', 'Anda tidak memiliki akses', 'error');

      if(e.status !== 2) {
        return Swal.fire('Maaf', 'Pembayaran Belum disetujui', 'error');
      } else {
        $('#payment').modal('show')
        // this.changePay(e)
      }
    },

    showEdit(e) {
      if(e.status !== 1) return Swal.fire('Maaf', 'Pembayaran anda tidak dapat diedit', 'error');
      this.isEdit = true
      this.add.BranchId = e.BranchId
      this.id = e.id
      this.add.notes = e.notes
      this.add.amount = this.setEditCurrent(e.amount)
      this.$refs.mainModal.show();
    },
    
    async getDataTable() {
      // get kendaraan
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/payment",
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.filter.keyword,
            BranchId: this.filter.findBranchId,
            status: this.filter.status,
            isApprove: this.filter.isApprove,
            isPay: this.filter.isPay,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.totalAmount = data.totalAmount,
        this.totalApprove = data.totalApprove,
        this.totalReject = data.totalReject,
        this.totalWaiting = data.totalWaiting,
        this.totalPaid = data.totalPaid,
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
        this.totalPage = totalPage
        this.isLoading = false
        console.log(data);
              
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },

    currencyNumber(event, target) {

        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.add.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        this.add.amount = this.formatUang(currentValue)

    },

    setEditCurrent(currentValue) {
      let result = 0
      if( Number.isNaN(Number(currentValue))) {
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
      }
      result = this.formatUang(currentValue)
      return result  
    },

    async addData() {
      
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        if(this.add.amount >= 2000000 && this.role !== 'payment') {
    
          return Swal.fire('Maaf', 'Jumlah Anda Terlalu Besar', 'error');
        }
        let data = await axios({
          url: '/payment',
          method: 'post',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.$refs.mainModal.hide();
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        this.isLoading = false
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    async editData() {
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        let data = await axios({
          url: `/payment/${this.id}`,
          method: 'put',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Diproses', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    
    
    async changePay(e) {

      try {
        // return alert("1")
        $('#payment').modal('hide')
        let data = await axios({
          method: 'PUT',
          url: `/payment/pay/${this.id}`,
          data: {
            date: this.date
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        // $('#payment').modal('hide')
        this.getDataTable()
        Swal.fire('Berhasil', 'Pembayaran anda telah dilakukan', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        this.getDataTable()
      }
    },
    async changeVerifed(e) {

      try {
        // for await (const item of this.data) {

        if(!e.ProjectId || !e.SubMataAnggaranId) return Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        let data = await axios({
          method: 'PUT',
          url: `/payment/verifed/${e.id}`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        // $('#payment').modal('hide')
        this.getDataTable()
        Swal.fire('Berhasil', 'Verifikasi anda telah dilakukan', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        this.getDataTable()
      }
    },

    async changeStatus(status) {
      
      try {
        let data = await axios({  
          method: 'post',
          url: `/payment/status`,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            status: status,
            checks: this.checks
          },
        })


        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        this.getDataTable()
        $('#statusModal').modal('hide')
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        this.getDataTable()
        $('#statusModal').modal('hide')
      }
    },

    async confirmDelete(e) {
      
      try {
        if(e.isPay) {
          throw {
            err: 500
          }
        } else {
          this.deleteData(e)
        }
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Proses Tidak Dapat dijalankan', 'error');
      }
    },

    async deleteData(e) {
      
      try {
        if(e.isPay) {
          Swal.fire('Maaf', 'Data tidak dapat dihapus', 'error');
        }
        let penm = await Swal.fire({
          title: 'Apakah anda yakin',
          text: "Hapus data?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then( async (result) => {
          if (result.isConfirmed) {

            let data = await axios({
              method: 'delete',
              url: `/payment/${e.id}`,
              headers: {
                access_token: localStorage.getItem("access_token")
              },
            })
            this.getDataTable()
            Swal.fire('Berhasil', 'Data Berhasil Diproses', 'success');
          } else {
            Swal.fire('Ok', 'Berhasil Dibatalkan', 'error');
          }
        })
            
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Proses Tidak Dapat dijalankan', 'error');
      }
    },

    async printCsv() {
      alert("yes")
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/payment/print",
          params: {
            keyword: this.filter.keyword,
            status: this.filter.status,
            isApprove: this.filter.isApprove,
            isPay: this.filter.isPay,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,  
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        const dataToConvert = {
          data: data,
          filename: 'Payment',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading= false
        alert("yes2")
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },

    formatDate(date) {
      let result = ''
      if(date) result = moment(date).format("DD-MM-YYYY")
      return result
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },

  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

/* dropdown */
.dropdown-container {
  position: relative;
  width: 300px;
}
.dropdown-header {
  font-size: 11px;
    padding: 5px;
    text-align: left;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #fff;
}
#input-dropdown {
  /* background: #000; */
  min-width: 15rem;
}
.dropdown {
    font-size: 12px;
    border: 1px solid #ccc;
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    max-width: 30rem;
    z-index: 1;
    margin-top: 50px;
    padding: 5px;
}
.dropdown-search {

  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;

}

.appButton {
  background-color: transparent;
    padding: 6px 12px;
    font-size: 16px;
    margin-left: 8px;
    border-radius: 8px;
    border: none;
}

.appButton#waiting {
  color: #a4a43c;

}
.appButton#approve {
  color: #28a745;

}
.appButton#reject {
  color: #e52903;
}
/* .appButton#waiting {
  background-color: #ffff01;
  color: black;
}
.appButton#approve {
  background-color: #28a745;
  color: #fff;
}
.appButton#reject {
  background-color: #e52903;
  color: #fff;
} */

.appButton img {
  width: 20px;
}



.dropdown-item:hover {
  background-color: #eee;
}


</style>